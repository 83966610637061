<template>
  <div class="personal">
    <div class="chat-head">
      <div class="head-left">
        <el-image class="head-icon" :src="require(`../../../assets/image/user.png`)" fit="contain"></el-image>
        <div class="head-title">个人中心</div>
      </div>
      <el-image class="head-right" @click="sendExit" :src="require(`../../../assets/image/exit2.png`)" fit="contain"></el-image>
    </div>
    <!-- 表单数据 -->
    <div class="myform">
      <el-form :model="userForm" ref="rulForm" :rules="rules">
        <div class="form-top">
          <div class="form-compo">
            <div class="form-compo-div">
              <el-form-item label="姓名">
                <el-input class="form-input" size="small" v-model="userForm.name" placeholder="请输入姓名"></el-input>
              </el-form-item>
            </div>
            <div class="form-compo-div">
              <el-form-item label="机构">
                <el-select
                  disabled
                  class="form-input"
                  v-model="userForm.ai_chain_id_new"
                  :filterable="true"
                  :remote="true"
                  placeholder="请搜索机构名称"
                  :remote-method="remoteMethod"
                  :loading="loading"
                  size="small"
                  @change="remoteChain"
                >
                  <el-option v-for="item in chainData" :key="item.shop_user_ai_chain_id" :label="item.chain_name" :value="item.shop_user_ai_chain_id"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="form-compo-div">
              <el-form-item label="密码">
                <el-input type="password" class="form-input" size="small" v-model="userForm.password" placeholder="请输入密码" show-password></el-input>
              </el-form-item>
            </div>
          </div>
          <div class="form-compo">
            <div class="form-compo-div">
              <el-form-item label="性别">
                <el-input class="form-input" size="small" v-model="userForm.gender" placeholder="请输入性别"></el-input>
              </el-form-item>
            </div>
            <div class="form-compo-div">
              <el-form-item label="手机号" prop="cell_phone">
                <el-input class="form-input" size="small" v-model="userForm.cell_phone" placeholder="请输入手机号"></el-input>
              </el-form-item>
            </div>
          </div>
        </div>
        <div class="form-button">
          <el-form-item>
            <el-button type="warning" size="mini" @click="submitForm">确定</el-button>
            <el-button size="mini" @click="resetForm">返回</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { aigetlistQuery, getAiUserDetails, insertUserDetails } from '../../../request/api'

export default {
  data() {
    return {
      userForm: {
        name: '',
        ai_chain_id_new: '',
        school: '',
        password: '',
        gender: '',
        cell_phone: ''
      },
      rules: {
        cell_phone: [
          { pattern: /^1[3-9]\d{9}$/, message: '请输入有效的手机号码', trigger: 'blur' }
        ],
      },
      chainData: [],
      loading: false
    }
  },
  methods: {
    sendExit() {
      this.$emit('update-value')
    },
    submitForm() {
      this.$refs.rulForm.validate(async (valid) => {
        if (valid) {
          try {
            const res = await insertUserDetails(this.userForm)
            if (res.status !== 200 || res.data.code !== "1000") {
              return this.$message.error(res.data.msg);
            }

            this.$message.success('保存成功')
          } catch(error) {
            this.$message.error('提交失败');
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    remoteChain(id) {
      this.chainData.forEach(ele => {
        if (ele.shop_user_ai_chain_id === id) {
          this.userForm.school = ele.chain_name
        }
      })
    },
    async remoteMethod(query) {
      this.loading = true
      try {
        const res = await aigetlistQuery(query)
        if (res.status !== 200 || res.data.code !== "1000") {
          return this.$message.error(res.data.msg);
        }

        this.chainData = res.data.data
        this.loading = false
      } catch(error) {
        this.$message.error('请求发生错误：' + error.message);
      }
    },
    // 返回
    resetForm() {
      this.$router.back()
    },
    // 初始化操作
    async init() {
      const res = await getAiUserDetails()
      if (res.status !== 200 || res.data.code !== "1000") {
        return this.$message.error(res.data.msg);
      }

      const details = res.data.data
      this.chainData = details.getAiChain
      this.userForm.ai_chain_id_new = details.getAiChain[0].shop_user_ai_chain_id
      this.userForm.school = details.getAiChain[0].chain_name
      if (details.getAiUser === null) return

      if (details.getAiUser.name !== null) this.userForm.name = details.getAiUser.name
      if (details.getAiUser.gender !== null) this.userForm.gender = details.getAiUser.gender
      if (details.getAiUser.cell_phone !== null) this.userForm.cell_phone = details.getAiUser.cell_phone
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.personal {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .myform {
    .form-top {
      background: #fff;
      padding: 22px;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      .form-compo {
        display: flex;
        .form-compo-div {
          width: 40%;
          .form-input {
            width: 66%;
          }
        }
      }
    }
    .form-button {
      background: #fff;
      padding: 20px 0px 2px 20px;
      box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.16);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      margin: 10px 0px;
    }
  }
  .chat-head {
    height: 8%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .head-left {
      display: flex;
      align-items: center;
      .head-icon {
        width: 40px;
        margin-right: 6px;
      }
      .head-title {
        font-size: 14px;
        color: #7a7c7e;
      }
    }
    .head-right {
      padding-right: 1%;
      width: 70px;
      cursor: pointer;
    }
  }
}
</style>